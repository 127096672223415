import { createTheme} from '@material-ui/core/styles';
import LandingPage from './home.js';

const theme = createTheme({
  palette: {
    primary: {
      main: '#D8D901',
    },
    secondary: {
      main: '#B2B306',
    },
},
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundImage: `url(https://cdn.saasmon.io/images/logowithwordmark-white.png)`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundColor: '#1B181B',
        }
      }
    }
  },
});


export default theme;
